.home {
  background-color: #fff;
}

.home .info {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  background-color: rgb(246, 246, 251);
  border-radius: 3px;
  text-align: center;
  padding: 30px;
}

.count {
  color: #fc5a58;
}

.sepa {
  height: 3px;
  width: 20px;
  background-color: rgb(55, 63, 73);
}

.courses__item {
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;

  .courses__show__header {
    color: #333;

    h4 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #313131;
      margin-bottom: 1rem;
    }
  }

  .courses__show__desc {
    font-weight: bold;
    font-size: 1rem;
    line-height: 2;
    color: gray;
    margin-bottom: 2rem !important;
  }

  .course__show__btn {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: white;
    background-color: #fc5a58;
    border-color: #fc5a58;

    &:hover {
      box-shadow: #fc5a58 0px 1px 4px;
      color: white;
    }
  }

  figure {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: hidden;

    img {
      width: 100%;
      height: 20rem;
    }
  }
}

.home {
  .course-slider__wrapper {
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
  }

  .home__slider-header {
    h2 {
      color: #fc5a58;
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
  }
}

.cards-data {
  &__item {
    height: 100%;
    border: 3px solid transparent;
    border-radius: 40px;
    padding-bottom: 30px;
    box-shadow: rgb(19 15 235 / 10%) 1px 2px 20px;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      transform: scale(1.04);
      border: 3px solid #fc5a58;
    }

    p {
      min-height: 50px;
    }

    a {
      display: inline-block;
      padding-bottom: 20px;
      color: #fc5a58;
    }
  }

}

.home .slick-slider.slick-initialized .slider__arrowLeft,
.home .slick-slider.slick-initialized .slider__arrowRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background-color: transparent;
  transform: rotate(180deg);
}

.home .slick-slider.slick-initialized .slider__arrowLeft {
  right: -30px;
}

.home .slick-slider.slick-initialized .slider__arrowRight {
  left: -30px;
}

.home .slick-slider.slick-initialized .slick-arrow::before {
  display: none !important;
}

.home .slick-slider.slick-initialized .slider__arrowLeft svg,
.home .slick-slider.slick-initialized .slider__arrowRight svg {
  width: 2rem;
  height: 2rem;
  color: #fc5a58;
}

@media (max-width: 991.98px) {
  .home {
    .course-slider__wrapper {
      padding-inline-end: 0;
      padding-inline-start: 0;
    }

    .slick-slider.slick-initialized .slider__arrowLeft,
    .slick-slider.slick-initialized .slider__arrowRight {
      display: none;
    }
  }
}