.my-learning__card {
  .card-title {
    color: #000 !important;
  }

  img {
    height: 14rem;
  }

  small {
    color: #707070 !important;
    font-size: 0.9rem;
  }
}
