@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&display=swap");

* {
  font-family: "Almarai", sans-serif;
}

a:hover,
a:focus,
a {
  text-decoration: none !important;
}

ul,
li {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  position: relative;
}