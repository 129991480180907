.course__sidebar {
  background: white;
  height: 100%;
  .ant-menu-inline {
    flex-direction: column;
  }

  .ant-menu-light .ant-menu-item:hover {
    background: rgba(#333, 0.13);
    color: #333;
  }
  .ant-menu-light .ant-menu-submenu-title:hover {
    background: rgba(#334, 0.05);
    // background: #fc5a58;
    // color: white;
    color: #333;
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #333;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #d1d2d2;
  }

  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    color: #333;
    background: rgba(#333, 0.05);
  }
  .ant-menu-title-content {
    font-size: 1rem;
    font-weight: 600;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: auto !important;
    margin-top: 0;
    line-height: 1.3;
    margin-bottom: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1.25rem !important;
  }
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
    border-bottom: 1px solid #b5b5b5;
    .submenu-time {
      font-size: 0.85rem;
      color: #717171;
      margin-top: 0.5rem;
    }
  }
  .ant-menu-inline .ant-menu-item::after {
    border-color: #fc5a58;
  }
  .course__sidebar-title {
    font-size: 0.9rem;
  }
  .course__sidebar-time {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #717171;
    .anticon {
      margin: 0;
      margin-inline-end: 0.5rem;
    }
  }
}
