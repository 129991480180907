.header {
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 3px #818181;
}


.header .navbar {
  width: 100%;
  display: flex;
}

.header .links {
  width: 50%;
  list-style: none;
}

.header .user__name.loged_in {
  position: relative;
}

.header .user__name.loged_in::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 2px;
  height: 50%;
  transform: translateY(-50%);
  background-color: #fc5a58;
}

.header .logo {
  margin-right: auto;
  width: 175px;
}

.header .links a {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.2s;
  border-radius: 3px;
  padding: 10px 5px;
}

.header .links a:hover {
  color: #fc5a58;
}

.header .links .link {
  width: auto;
}

.navbar-collapse {
  z-index: 5;
  background-color: #fff;
}

.active {
  color: #fc5a58 !important;
  font-weight: 600;
}


.navbar-nav-scroll {
  max-height: fit-content !important;
}

.header {
  .dropdown {
    .btn:focus {
      box-shadow: none !important;
    }
  }

  .my-learning {
    &__dropdown {

      .dropdown-item:focus,
      .dropdown-item:hover,
      .dropdown-item:active {
        background-color: transparent;
        color: inherit;
      }

      .ant-progress-bg {
        background: #fc5a58;
      }
    }

    &__fig {
      width: 70px;
      border-radius: 5px;
      overflow: hidden;
      margin-inline-end: 1rem;
      margin-bottom: 0 !important;

      img {
        width: 100%;
      }
    }

    &__desc {
      min-width: 150px;

      h6 {
        font-size: 0.8rem;
        font-weight: 700;
      }
    }

    &__btn {
      color: white;
      background-color: #fc5a58;
      border: 0;

      &:hover {
        color: white;
        background-color: #fc5a58;
      }
    }
  }
}

@media (max-width:991.98px) {
  .header .user__name.loged_in::before {
    display: none;
  }
}

@media (max-width: 768px) {
  .logo {
    margin-top: 0.8rem;
  }

  .logo img {
    width: 80% !important;
  }
}

@media (min-width: 769px) {
  .container-fluid {
    align-items: center !important;
  }

  .logo img {
    width: 100% !important;
  }
}

.header .navbar-nav a {
  border-bottom: 2px solid #fff;
  transition: border-bottom .25s ease-in-out;
}



.header .navbar-nav a:hover {
  border-bottom: 2px solid #fc5a58;
}

.header .navbar-nav .my-learning__btn a,
.header .navbar-nav .my-learning__dropdown a {
  border-bottom: none;
}

.header .navbar-nav .my-learning__dropdown a:hover {
  background-color: rgb(238, 236, 236) !important;
}

.header .navbar-nav .my-learning__btn a:hover {
  background-color: transparent !important;
}

.header .my-learning__btn {
  margin-block: 10px;
}