.module__questions {
    height: 100%;
    color: #333;
    background: rgba(51, 51, 51, 0.05);
    border-inline-start: 1px solid #b5b5b5;
    border-block-end: 1px solid #b5b5b5;

    .wrapper {
        max-height: 350px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 7px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }

        .module__questionsForm {
            margin-block-end: 20px;

            .question {
                margin-block: 20px;
            }

            .input-group {
                margin-block: 5px;

                &:has(input[type="radio"]:checked) label {
                    background-color: #d1d2d2;
                    border-color: #d1d2d2;
                }

                &:has(input[type="radio"]) label {
                    &.checked__correctAnswer {
                        background-color: green;
                        color: white;
                    }

                    &.checked__incorrectAnswer {
                        background-color: red;
                        color: white;
                    }
                }

                .input-group-text {
                    display: none;
                }

                .answer__mark {
                    width: 35px;
                }

                label {
                    width: 400px;
                    border: 1px solid black;
                    border-radius: 5px !important;
                    padding-block: 10px;
                    cursor: pointer;
                }
            }
        }

        .form__button {
            margin-block: 15px;
        }
    }
}