/* start style PrivacyPolicy */
.PrivacyPolicy {
  h2.PrivacyPolicy_header {
    position: relative;
    margin: 20px 0;

    &::after {
      content: "";
      position: absolute;
      height: 3px;
      left: 0;
      width: 50vh;
      background-color: #fc5a58;
      top: 50%;
    }

    &::before {
      content: "";
      position: absolute;
      height: 3px;
      right: 0;
      width: 50vh;
      background-color: #fc5a58;
      top: 50%;
    }
  }

  .PrivacyPolicy_item {
    b {
      color: #fc5a58;
    }

    .PrivacyPolicy_item_icon {
      color: #fc5a58;
      font-size: 22px;
    }
  }
}

/* end style PrivacyPolicy */