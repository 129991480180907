.login-page form,
.register-page form {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  // box-shadow: rgba(#fc5a58, 90%) 1px 1px 4px;
  padding: 0 24px;

  &:not(:last-of-type) {
    border-bottom-color: transparent;
  }

  &:not(:first-of-type) {
    border-top-color: transparent;
  }
}

.login-page form .button button,
.register-page .button button {
  background-color: #32dd88 !important;
  border-color: #32dd88 !important;
  color: #fff !important;
  padding: 10px 30px;
  font-weight: 600;
  margin: 0 auto;
}

.login-page form .button button:hover,
.register-page .button button:hover {
  background-color: #23d37b !important;
  border-color: #32dd88 !important;
}

.login-title,
.register-title {
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  font-size: 1.802rem;
}

.check-container {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: flex-start;

  input {
    &:focus {
      box-shadow: none;
    }
  }
}

.remember {
  flex-basis: 20%;
}

.forget {
  flex-basis: 80%;
  color: #32dd88;
  text-align: end;
}

.forget:hover {
  color: #189155;
}

.input {
  border-radius: 5px !important;
  padding-left: 8px;
  padding-right: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.registeration-header,
.registeration-footer {
  background-color: #fff;
  text-align: center;
}

.registeration-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 40px 0;
  width: 100%;
  color: #bac1c7;
}

.form-control {
  direction: rtl !important;

  &:focus {
    box-shadow: none !important;
  }
}

.errorMsg {
  position: fixed;
  left: 0;
  right: 0;
  width: 30%;
  margin: 0 auto;
  border-radius: 5px;
  bottom: 20%;
  z-index: 2;
  font-size: 1.3rem;
}

.worrning {
  margin: 0 auto;
}

@media screen and (max-width: 580px) {
  .errorMsg {
    width: 70%;
    font-size: 1rem;
  }
}

.resset_password_button {
  border: none;
  background-color: transparent;

  &:disabled {
    color: #2a2a2a;
    opacity: 0.45;
  }
}
