@import "../../../../assets/scss/media-mixins";
.course {
  &__details {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    margin-bottom: 1rem;
    p {
      font-size: 1rem !important;
      font-weight: 600;
      color: #545454;
    }
  }
}

@include to-tablet-sm {
  .course {
    &__details {
      display: none;
    }
  }
}
