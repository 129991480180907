@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&display=swap");
@import "react-slideshow-image/dist/styles.css";
* {
  font-family: "Almarai", sans-serif;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  width: 100% !important;
}

main {
  flex: 1;
}
