.pricing-page .title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 100px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  line-height: 65px;
}

.pricing-page .invest {
  color: #fc5a58;
}

.pricing-page .plans {
  flex-wrap: wrap;
}

.pricing-page .plan {
  display: flex;
  flex-direction: column;
  box-shadow: rgb(19 15 235 / 10%) 1px 2px 20px;
  text-align: center;
  padding: 38px 42px 42px;
  border-radius: 25px;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 3px solid transparent;
}

.pricing-page .plan:hover {
  transform: scale(1.04);
  border: 3px solid #fc5a58;
}

.annual-plan {
  border: 3px solid #fc5a58;
}

@media (max-width: 770px) {
  .pricing-page .plan {
    flex-basis: 100% !important;
  }
}

@media (max-width: 1200px) {
  .pricing-page .plan {
    flex-basis: 45%;
  }
}

@media (min-width: 1201px) {
  .pricing-page .plan {
    flex-basis: 24%;
  }
}

.pricing-page button {
  padding: 10px;
  width: 100%;
  border-radius: 999px;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  font-size: 18px;
  font-weight: 700;
  color: #fc5a58;
  border: 2px solid #fc5a58;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.pricing-page button:hover {
  background-color: #fc5a58 !important;
  color: #fff !important;
}

.annual-button {
  background-color: #fc5a58 !important;
  color: #fff !important;
}

.annual-button:hover {
  background-color: rgb(187, 32, 88) !important;
}

.plan-title {
  color: #fc5a58;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.plan-price {
  font-size: 40px;
  line-height: 50px;
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.separator {
  display: block;
  width: 25px;
  height: 3px;
  margin: 0px auto 20px;
  border-radius: 20%;
  background-color: rgb(217, 217, 217);
}

.plan-desc {
  margin: 30px 0px;
  color: rgb(55, 63, 73);
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}

.includes {
  padding: 50px 0;
}

.includes-title {
  margin: 10px 0;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: rgb(0, 0, 0);
}

.includes-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.includes-point {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px 0;
}

.circle {
  margin-left: 10px;
  flex-basis: 10%;
}

.point-desc {
  color: rgb(55, 63, 73);
  font-size: 20px;
  line-height: 30px;
}
