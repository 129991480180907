.img-container img {
  width: 265px;
  height: 265px;
  border-radius: 50%;
  margin: 0px auto;
  object-fit: cover;
  box-shadow: rgb(19 15 235 / 10%) 2px 4px 15px 0px;
}
.social-container a {
  color: #000;
}
